import Repository, { apiUrl } from './index';

class UploadRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async validatePhoto(payload) {
        let url = `${apiUrl}/photo-validate`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch((error) => { 
                return error.response.data;
             });
        return reponse;
    }

    async validateDoc(payload) {
        let url = `${apiUrl}/doc-validate`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return error.response.data;
            });
        return reponse;
    }

    async uploadData(payload) {
        let url = `${apiUrl}/upload`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => { 
                return error.response.data;
            });
        return reponse;
    }
}

export default new UploadRepository();