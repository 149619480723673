import React from 'react';
import ReactDOM from 'react-dom/client';

import './assets/css/gobal.css';
import 'antd/dist/reset.css';
import './assets/css/antd.customize.css';
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

