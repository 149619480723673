import React from 'react';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';  

//Pages
import Home from './pages/upload';
import Track from './pages/track';

//Components
import Navbar from './components/layouts/Navbar';
import Footer from './components/layouts/Footer';

export default function App() {
  return (
    <Router>
        <Navbar />
        <main className='position-relative'>
          <Routes>  
            <Route exact path='/' element={<Home />}></Route>  
            <Route exact path='/track' element={<Track />}></Route>
          </Routes>
          <Footer />
        </main>
    </Router>
  )
}
