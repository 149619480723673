import React, { useEffect, useState, useRef, useCallback } from 'react'
import Webcam from "react-webcam";
import { message, Modal, Tooltip, Spin } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import UploadRepository from '../repository/UploadRep';
import shortid from 'shortid';

const videoVertical = {
  width: 270,
  height: 330,
  facingMode: "user"
};

const videoHorizontal = {
  width: 430,
  height: 212,
  facingMode: "user"
};

export default function Home(props) {
  const reupload = props.trackCode ? true : false;

  const webcamRef = useRef(null);
  const uploader = useRef(null)

  const [uploadType, setUploadType] = useState('file');

  const [userPhoto, setUserPhoto] = useState('');
  const [documents, setDocuments] = useState([]);
  const [loadingObj, setLoadingObj] = useState({});
  const [errorObj, setErrorObj] = useState({});

  const [showWebcam, setShowWebcam] = useState(false);
  const [webcamAccess, setWebcamAccess] = useState(true);

  const [trackCode, setTrackCode] = useState(props.trackCode);
  const [showModal, setShowModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  // const config = {
  //   name: 'file',
  //   multiple: false,
  //   showUploadList: false,

  //   onChange(info) {
  //     console.log(info);
  //     const { status } = info.file;
  //     if (status !== 'uploading') {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (status === 'done') {
  //       message.success(`${info.file.name} file uploaded successfully.`);
  //     } else if (status === 'error') {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //     setDocuments(info.fileList)
  //   }
  // };

  useEffect(() => {
    if (reupload) {
      setUserPhoto('')
      setDocuments([])
      setLoadingObj([{}])
      setErrorObj([{}])
    }
  }, [])


  const checkWebcam = () => {
    var facingMode = "user";
    var constraints = {
      audio: false,
      video: {
        facingMode: facingMode
      }
    };

    window.navigator.mediaDevices.getUserMedia(constraints).then(function success(stream) {
      setWebcamAccess(true)
      console.log('Granted');
    })
      .catch(function (e) {
        setWebcamAccess(false)
        console.log('Dental');
      });
  }

  const getWebcam = () => {
    setShowWebcam(true)
    checkWebcam()
  }

  const uploadByWebcam = () => {
    checkWebcam()
    setUploadType('webcam')
  }

  const uploadCamDoc = (fileSrc, fileName) => {
    let files = [...documents]
    let obj = {}
    obj['name'] = fileName
    obj['src'] = fileSrc;
    files.push(obj)
    setDocuments(files)
  }

  const capture = () => {
    let error = { ...errorObj }
    let loading = { ...loadingObj }

    const imageSrc = webcamRef.current.getScreenshot();
    if (!userPhoto) {
      error['photo'] = false
      loading['photo'] = true
      validatePhoto(imageSrc).then(response => {
        if (response) { setUserPhoto(imageSrc) }
      })
        .catch(error => ({ error: JSON.stringify(error) }));
    } else {
      setUploadType('file')
      if (documents.length == 0) {
        error['doc_front'] = false
        loading['doc_front'] = true
      } else {
        error['doc_back'] = false
        loading['doc_back'] = true
      }
      validateDoc(imageSrc).then(response => {
        if (response) {
          if (documents.length == 0) {
            error['doc_front'] = false
            loading['doc_front'] = true
            uploadCamDoc(imageSrc, 'doc-front.jpeg')
          } else {
            error['doc_back'] = false
            loading['doc_back'] = true
            uploadCamDoc(imageSrc, 'doc-back.jpeg')
          }
        }
      })
        .catch(error => ({ error: JSON.stringify(error) }));
    }
    setErrorObj(error)
    setLoadingObj(loading)
    setShowWebcam(false)
  }

  const validatePhoto = async (data) => {
    let error = { ...errorObj }
    let loading = { ...loadingObj }

    let result = await UploadRepository.validatePhoto({ data: data })
    if (result?.error) {
      message.error(result?.message);
      error['photo'] = true
      loading['photo'] = false
      setErrorObj(error)
      setLoadingObj(loading)
      return false;
    } else {
      message.success(result?.message);
      error['photo'] = false
      loading['photo'] = false
      setErrorObj(error)
      setLoadingObj(loading)
      return true;
    }
  }

  const downloadFile = (fileSrc, fileName) => {
    var a = document.createElement("a");
    a.href = fileSrc;
    a.download = fileName;
    a.click();
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const validateDoc = async (data) => {
    let error = { ...errorObj }
    let loading = { ...loadingObj }

    let result = await UploadRepository.validateDoc({ data: data })
    if (result?.error) {
      message.error(result?.message);
      if (documents?.length === 0) {
        error['doc_front'] = true
        loading['doc_front'] = false
      } else {
        error['doc_back'] = true
        loading['doc_back'] = false
      }
      setErrorObj(error)
      setLoadingObj(loading)
      return false;
    } else {
      message.success(result?.message);
      if (documents?.length === 0) {
        error['doc_front'] = false
        loading['doc_front'] = false
      } else {
        error['doc_back'] = false
        loading['doc_back'] = false
      }
      setErrorObj(error)
      setLoadingObj(loading)
      return true;
    }
  }

  const onFileUpload = (e) => {
    let files = [...documents]
    let loading = { ...loadingObj }
    let error = { ...errorObj }
    let obj = {}
    let file = e.target.files[0]
    // let fileUrl = URL.createObjectURL(file)
    obj['name'] = file?.name
    if (file?.size > 5242880) {
      if (files?.length === 0) {
        error['doc_front'] = true
      } else {
        error['doc_back'] = true
      }
      setErrorObj(error)
      message.error("File is too big!, Upload below 5MB file.")
    } else {
      setErrorObj([{}])
      if (files?.length === 0) {
        loading['doc_front'] = true
      } else {
        loading['doc_back'] = true
      }
      setLoadingObj(loading)
      getBase64(file).then(
        data => {
          validateDoc(data).then(response => {
            if (response) {
              obj['src'] = data;
              files.push(obj)
              setDocuments(files)
            }
          })
            .catch(error => ({ error: JSON.stringify(error) }));
        }
        // downloadFile(data, file?.name)
      );
    }
  }

  const sumbitDoc = async () => {
    let payloadObj = {}
    if (userPhoto !== null && documents?.length === 2) {
      let tc = ''
      if (reupload) {
        payloadObj['tracker_id'] = trackCode;
      } else {
        const desiredLength = 8;
        shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@');
        const uniqueId = shortid.generate().substring(0, desiredLength);
        tc = 'NLTRACK' + uniqueId.toUpperCase();
        payloadObj['tracker_id'] = tc;
      }
      payloadObj['photo'] = userPhoto;
      payloadObj['doc_front'] = documents[0].src;
      payloadObj['doc_back'] = documents[1].src;
      // console.log(payloadObj);
      setSubmitLoading(true)
      let result = await UploadRepository.uploadData(payloadObj)
      if (result?.error) {
        message.error(result?.message);
        setSubmitLoading(false)
        setUserPhoto('');
        setDocuments([]);
        setLoadingObj([{}]);
        setErrorObj([{}]);
      } else {
        message.success(result?.message);
        setSubmitLoading(false)
        setUserPhoto('')
        setDocuments([])
        setLoadingObj([{}])
        setErrorObj([{}])

        setShowModal(true)

        if (!reupload) {
          setTrackCode(tc)
        }
      }
    } else {
      message.error('All documents are required, Upload and submit.');
    }
  }

  const copyTrackCode = () => {
    navigator.clipboard.writeText(trackCode)
    setIsCopied(true)
  }

  return (
    <section className={`bg-style pt-m-0 ${reupload ? 'pt-2 h-m-0' : ''}`}>
      <div className={`upload-card mt-3 ${reupload ? 'reupload-card' : ''}`}>
        <div className="d-flex flex-column flex-md-row">
          <div className='upload-area me-md-3'>
            {!userPhoto ?
              <div>
                {showWebcam ?
                  <div className='webcam-card'>
                    {webcamAccess ?
                      <div className="webcam-stream">
                        <Webcam
                          audio={false}
                          ref={webcamRef}
                          mirrored={true}
                          screenshotQuality={1}
                          imageSmoothing={true}
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoVertical}
                        />
                        <button className='take-photo-btn' onClick={capture}>Click here to take photo</button>
                      </div>
                      :
                      <div>
                        <i className="far fa-webcam-slash"></i>
                        <h6>Webcam access denied</h6>
                        <p>We need your real time photo on webcam</p>
                      </div>
                    }
                  </div>
                  :
                  <div className={`photo-upload ${loadingObj['photo'] ? 'disable-upload' : ''}`} onClick={getWebcam}>
                    <i className="far fa-webcam"></i>
                    <h6>Click to take photo</h6>
                    <p>Need your real time photo on webcam</p>
                  </div>
                }
              </div>
              :
              <div className='d-flex justify-content-center align-items-center h-100'>
                {uploadType === 'file' ?
                  <div className='custom-dragger p-0'>
                    <div className='custom-btn' onClick={() => uploader.current.click()}>
                      <input type="file" accept="image/*" name="" id="" ref={uploader} onChange={onFileUpload} className='d-none' />
                      <i className="fal fa-file-upload"></i>
                      <h6>Click to upload file</h6>
                      {userPhoto ?
                        documents?.length ?
                          <p>Document back side</p>
                          : <p>Document front side</p>
                        : ""
                      }
                    </div>
                    <div className='custom-btn' onClick={uploadByWebcam}>
                      <i className="far fa-webcam"></i>
                      <h6>Click to take photo</h6>
                      <p>Place your doc on webcam</p>
                    </div>
                  </div>
                  :
                  <div className='webcam-card webcam-horizontal'>
                    {webcamAccess ?
                      <div className="webcam-stream">
                        <Webcam
                          audio={false}
                          imageSmoothing={true}
                          screenshotQuality={1}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoHorizontal}
                        />
                        <button className='take-photo-btn' onClick={capture}>Click here to take photo</button>
                        <button className='close-btn' onClick={() => setUploadType('file')}><i className="fal fa-times-circle"></i></button>
                      </div>
                      :
                      <div>
                        <i className="far fa-webcam-slash"></i>
                        <h6>Webcam access denied</h6>
                        <p>We need your real time photo on webcam</p>
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>
          <div className="upload-list ms-md-3 mt-4 mt-md-0">
            {/* <h4>Files to be {reupload ? 'reuploaded' : 'uploaded'}</h4> */}
            <ol>
              <li>
                <label>Your photo</label>
                <div className={`upload-file-card ${!userPhoto ? 'active' : ''}`}>
                  <div className="d-flex align-items-center">
                    <i className="far fa-camera-alt color-primary"></i>
                    <p>{userPhoto ? 'photo.jpeg' : 'Only webcam photo is allowed.'}</p>
                  </div>
                  {userPhoto &&
                    <i className="far fa-check-circle color-green"></i>
                  }
                  {loadingObj['photo'] &&
                    <i className="fad fa-spinner-third rotate-ani"></i>
                  }
                  {errorObj['photo'] &&
                    <i className="far fa-times-circle color-red"></i>
                  }
                </div>
              </li>
              <li>
                <label>Document front side</label>
                <div className={`upload-file-card ${userPhoto && documents?.length == 0 ? 'active' : ''}`}>
                  <div className="d-flex align-items-center">
                    <i className="far fa-id-card color-primary"></i>
                    <p>{documents?.length > 0 ? documents[0]?.name : 'Only images with file size of max 5 MB is allowed.'}</p>
                  </div >
                  {documents?.length > 0 &&
                    <i className="far fa-check-circle color-green"></i>
                  }
                  {
                    loadingObj['doc_front'] &&
                    <i className="fad fa-spinner-third rotate-ani"></i>
                  }
                  {
                    errorObj['doc_front'] &&
                    <i className="far fa-times-circle color-red"></i>
                  }
                </div >
              </li >
              <li>
                <label>Document back side</label>
                <div className={`upload-file-card ${documents?.length == 1 ? 'active' : ''}`}>
                  <div className="d-flex align-items-center">
                    <i className="far fa-window-maximize color-primary"></i>
                    <p>{documents?.length > 1 ? documents[1]?.name : 'Only images with file size of max 5 MB is allowed.'}</p>
                  </div >
                  {documents?.length > 1 &&
                    <i className="far fa-check-circle color-green"></i>
                  }
                  {
                    loadingObj['doc_back'] &&
                    <i className="fad fa-spinner-third rotate-ani"></i>
                  }
                  {
                    errorObj['doc_back'] &&
                    <i className="far fa-times-circle color-red"></i>
                  }
                </div >
              </li >
            </ol >
          </div >
        </div >
        <button className={`submit-btn ${submitLoading ? 'is-loading' : ''}`} onClick={sumbitDoc}>
          <span className='loading-state'><Spin /> &nbsp;&nbsp;Uploading</span>
          <span className='submit-state'><i className="far fa-cloud-upload"></i> Submit</span>
        </button>
        <Modal title={`Documents ${reupload ? 'reuploaded' : 'uploaded'} successfully`} centered width={400} className='custom-modal' open={showModal} maskClosable={reupload ? true : isCopied} onOk={() => setShowModal(false)} onCancel={() => setShowModal(false)} okButtonProps={{ disabled: (!isCopied && !reupload) }} cancelButtonProps={{ hidden: true }} closable={(isCopied || reupload)}>
          <span className='icon'><i class="far fa-check-circle"></i></span>
          {reupload ?
            <p className='content'>Track your reupload document status with your old track code</p>
            :
            <>
              <p className='content'>Copy the following code and use this to track your document status on&nbsp;<b>"Track Submitted"</b>&nbsp;page</p>
              <div className="copy-box">
                <input type="text" value={trackCode} disabled={true} />
                <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
                  <button onClick={copyTrackCode} className={isCopied ? 'copy-btn-active' : ''}>
                    <i className={`${isCopied ? 'fad' : 'far'} fa-copy`}></i>
                  </button>
                </Tooltip>
              </div>
              <p className='note-text'>*Don't forgot this code, It's unrecovered.</p>
            </>
          }
        </Modal>
      </div>
    </section >
  )
}
