import React from 'react'
import { Link } from 'react-router-dom';

import Logo from '../../assets/img/logo-with-text.png'

export default function Navbar() {
    return (
        <nav className='navbar'>
            <div className="container">
                <div className="d-flex justify-content-between align-items-center w-100">
                    <div>
                        <img src={Logo} className='logo' alt="North Lark" />
                        <h5>Consular</h5>
                    </div>
                    <div className="nav-items">
                        <Link to={'/'}>
                            <span className='nav-link'><i className="far fa-cloud-upload"></i> <b>Start KYC</b></span>
                        </Link>
                        <Link to={'/track'}>
                            <button className='nav-btn'><i className="far fa-location"></i> <b>Track Status</b></button>
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    )
}
