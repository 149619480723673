import React, { useEffect, useState } from 'react'
import UploadModel from '../pages/upload';
import { Table, Tag, Modal, message } from 'antd';
import moment from 'moment/moment';
import TrackRepository from '../repository/TrackRep';

export default function Track() {
  const [trackCode, setTrackCode] = useState('')
  const [inputValid, setInputValid] = useState(false)
  const [trackLoading, setTrackLoading] = useState(false)
  const [trackStatus, setTrackStatus] = useState([])
  const [resubmitShow, setResubmitShow] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [dataId, setDataId] = useState(false)
  const openModal = (id) => {
    setShowModal(true)
    setDataId(id)
  }
  const columns = [
    {
      title: 'S.No',
      dataIndex: 'key',
    },
    {
      title: 'Track ID',
      dataIndex: 'id',
    },
    {
      title: <>Submitted&nbsp;Date</>,
      dataIndex: 'date',
    },
    {
      title: 'Current Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>
          {status.map((s) => {
            let color = '';
            switch (s) {
              case 'PROCESSING':
                color = 'gold'
                break;
              case 'PENDING':
                color = 'red'
                break;
              case 'APPROVED':
                color = 'green'
                break;
            }
            return (
              <Tag color={color} key={s}>{s}</Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      width: 300
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
    },
  ];
  const getTrackCode = (value) => {
    setTrackCode(value)
  }
  const trackDoc = () => {
    if (trackCode.length === 15) {
      setInputValid(false)
      let array = []
      setTrackLoading(true)
      TrackRepository.getDocStatus(trackCode).then(response => {
        if (response) {
          console.log(response, "response")
          if (response?.error) {
            message.error(response?.message);
            setTrackLoading(false)
            setTrackStatus([])
          } else {
            console.log(response, "response")
            message.success(response?.message);
            let obj = {}
            obj['key'] = 1
            obj['id'] = response.data._id
            obj['status'] = [response.data.status]
            obj['date'] = moment.utc(response.data.submmited_date).format('LLL');
            obj['comment'] = response.data.reason
            obj['actions'] = (
              <button className={`resubmit-btn1 ${response.data.status === "PROCESSING" ? 'disable-upload' : ''}`} onClick={() => openModal(response.data)}>
                <i className="far fa-eye"></i> View</button>
            )
            array.push(obj)
            if (response.status === 'PENDING') {
              setResubmitShow(true)
            }
            setTrackStatus(array)
            setTrackLoading(false)
          }
        }
      })
        .catch(error => {
          message.error(error?.message)
          setTrackLoading(false)
        })
    } else {
      setInputValid(true)
      message.error("Invalid track code")
    }
  }

  const reuploadFiles = () => {
    setModalShow(true)
  }

  return (
    <section className='bg-style h-100vh'>
      <div className='track-card'>
        <div className="d-flex flex-column flex-md-row g-0 justify-content-between align-items-end">
          <div className={`track-input ${inputValid ? 'track-input-error' : ''}`}>
            <label htmlFor="trackCode">Track your document status</label>
            <div className="d-flex">
              <input type="text" id='trackCode' name='trackCode' onChange={(e) => getTrackCode(e.target.value)} placeholder='Enter your track code' />
              <button onClick={trackDoc}><i className="far fa-search"></i>Search</button>
            </div>
          </div>
          {resubmitShow &&
            <button className="resubmit-btn" onClick={reuploadFiles}><i className="far fa-redo"></i> <b>Resubmit</b></button>
          }
        </div>
        {/* <div className={`track-input ${inputValid ? 'track-input-error' : ''}`}>
          <label htmlFor="trackCode">Report Summary</label>
        </div> */}
        <Table columns={columns} dataSource={trackStatus} pagination={false} className='custom-table' loading={trackLoading} />
        <Modal title="Reupload documents" centered width={'max-content'} className='custom-modal reupload-modal' open={modalShow} onCancel={() => setModalShow(false)} footer={false}>
          <p className='mt-1'></p>
          <UploadModel trackCode={trackCode} />
        </Modal>

        <Modal title="Processed Details" centered width={700} className='custom-modal'
          open={showModal} onOk={() => setShowModal(false)}
          onCancel={() => setShowModal(false)} cancelButtonProps={{ hidden: true }} >
          <div className='modal-popup mt-4'>
            <div className='row g-0'>
              <div className='col-4'>
                <h5>Name</h5>
              </div>
              <div className='col-1 text-center'>:</div>
              <div className='col-7 text-start'>
                <h6>
                  {dataId.name ? <><i className="far fa-user me-1"></i>{dataId.name.join(" ")}</> : 'N/A'}  </h6>
              </div>
            </div>
            <div className='row g-0'>
              <div className='col-4'>
                <h5>DOB</h5>
              </div>
              <div className='col-1 text-center'>:</div>
              <div className='col-7 text-start'>
                <h6>
                  {dataId?.dob ? <><i class="far fa-calendar me-1"></i>{dataId?.dob}</> : 'N/A'}</h6>
              </div>
            </div>
            <div className='row g-0'>
              <div className='col-4'>
                <h5>Document ID</h5>
              </div>
              <div className='col-1 text-center'>:</div>
              <div className='col-7 text-start'>
                <h6>{dataId?.card_id ? <><i className="far fa-id-badge me-1"></i>{ Array.isArray(dataId?.card_id) ? dataId?.card_id[0] : dataId?.card_id }</> : 'N/A'}</h6>
              </div>
            </div>
            <div className='row g-0'>
                <div className='col-4'>
                  <h5>Personal Number</h5>
                </div>
                <div className='col-1 text-center'>:</div>
                <div className='col-7 text-start'>
                  <h6>{Array.isArray(dataId?.card_id) ? <><i className="far fa-id-badge me-1"></i>{dataId?.card_id[1]}</> : 'N/A'}</h6>
                </div>
            </div>
            <div className='row g-0'>
              <div className='col-4'>
                <h5>ID Type</h5>
              </div>
              <div className='col-1 text-center'>:</div>
              <div className='col-7 text-start'>
                <h6>
                  {dataId?.card_type ? <><i className="far fa-id-badge me-1"></i>
                    {dataId?.card_type}</> : 'N/A'}</h6>
              </div>
            </div>
            <div className='row g-0'>
              <div className='col-4'>
                <h5>Submitted Date</h5>
              </div>
              <div className='col-1 text-center'>:</div>
              <div className='col-7 text-start'>
                <h6>
                  {dataId?.submmited_date ? <> <i className="far fa-calendar me-1"></i>
                    {moment.utc(dataId?.submmited_date).format('MMMM Do YYYY, h:mm:ss a')}</> : 'N/A'}</h6>
              </div>
            </div>
            <div className='row g-0'>
              <div className='col-4'>
                <h5>Expiry Date</h5>
              </div>
              <div className='col-1 text-center'>:</div>
              <div className='col-7 text-start'>
                <h6>
                  {dataId?.Expiry_date ? <> <i className="far fa-calendar me-1"></i>
                    {dataId?.Expiry_date}</> : 'N/A'}</h6>
              </div>
            </div>
            {/* <div className='row g-0'>
              <div className='col-4'>
                <h5>Process Start Time</h5>
              </div>
              <div className='col-1 text-center'>:</div>
              <div className='col-7 text-start'>
                <h5 style={{ fontWeight: 400, fontSize: '16px' }}>
                  {dataId?.queue_start_time ? <> <i className="far fa-clock me-1"></i>
                    {moment.utc(dataId?.queue_start_time).format('MMMM Do YYYY, h:mm:ss a')}</> : 'N/A'}</h5>
              </div>
            </div>
            <div className='row g-0'>
              <div className='col-4'>
                <h5>Process End Time</h5>
              </div>
              <div className='col-1 text-center'>:</div>
              <div className='col-7 text-start'>
                <h5 style={{ fontWeight: 400, fontSize: '16px' }}>
                  {dataId?.queue_end_time ? <> <i className="far fa-clock me-1"></i>
                    {moment.utc(dataId?.queue_end_time).format('MMMM Do YYYY, h:mm:ss a')}</> : 'N/A'}</h5>
              </div>
            </div> */}
            <div className='row g-0'>
              <div className='col-4'>
                <h5>Face Recognition Time</h5>
              </div>
              <div className='col-1 text-center'>:</div>
              <div className='col-7 text-start'>
                <h6>{dataId?.face_match_time ? <> <i className="far fa-clock me-1"></i>
                    {Math.round(dataId?.face_match_time)} secs</> : 'N/A'}</h6>
              </div>
            </div>
            {/* <div className='row g-0'>
              <div className='col-4'>
                <h5>Card Prediction Time</h5>
              </div>
              <div className='col-1 text-center'>:</div>
              <div className='col-7 text-start'>
                <h5>
                  {dataId?.image_prediction_time ? <> <i className="far fa-clock me-1"></i>
                    {Math.round(dataId?.image_prediction_time)} secs</> : 'N/A'}</h5>
              </div>
            </div> */}
            <div className='row g-0'>
              <div className='col-4'>
                <h5>Text Extraction Time</h5>
              </div>
              <div className='col-1 text-center'>:</div>
              <div className='col-7 text-start'>
                <h6>
                  {dataId?.process_time ? <> <i className="far fa-clock me-1"></i>
                    {Math.round(dataId?.process_time)} secs</> : 'N/A'}</h6>
              </div>
            </div>
            <div className='row g-0'>
              <div className='col-4'>
                <h5>Total Processing Time</h5>
              </div>
              <div className='col-1 text-center'>:</div>
              <div className='col-7 text-start'>
                <h6><i className="far fa-clock me-1"></i>{Math.round((dataId?.face_match_time + dataId?.image_prediction_time + dataId?.process_time))} secs</h6>
              </div>
            </div>
            <div className='row g-0'>
              <div className='col-4'>
                <h5>Comments</h5>
              </div>
              <div className='col-1 text-center'>:</div>
              <div className='col-7 text-start'>
                {dataId?.reason ? <h6>
                  {dataId?.reason}</h6> : "N/A"}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </section >
  )
}
