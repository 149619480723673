import Repository, { apiUrl } from './index';

class TrackRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getDocStatus(trackID) {
        let url = `${apiUrl}/track/${trackID}`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => { 
                return error.response.data;
            });
        return reponse;
    }
    async getData() {
        let url = `${apiUrl}/alltracks`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => { 
                return error.response.data;
            });
        return reponse;
    }
}

export default new TrackRepository();